import Image from "next/image";
import { useRef, useEffect } from "react";

interface TiltingImgProps {
  src: string;
  alt: string;
  width: number;
  height: number;
}

const TiltingImg: React.FC<TiltingImgProps> = ({ src, alt, width, height }) => {
  const svgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleMouseEnter = (event: MouseEvent) => {
      if (svgRef.current) {
        const svgRect = svgRef.current.getBoundingClientRect();
        const centerX = svgRect.left + svgRect.width / 2;
        const centerY = svgRect.top + svgRect.height / 2;

        const tiltX = (event.clientX - centerX) / (svgRect.width / 2);
        const tiltY = (event.clientY - centerY) / (svgRect.height / 2);

        svgRef.current.style.transform = `rotateX(${-tiltY * 10}deg) rotateY(${
          tiltX * 10
        }deg)`;
      }
    };

    const handleMouseLeave = () => {
      if (svgRef.current) {
        svgRef.current.style.transform = "rotateX(0deg) rotateY(0deg)";
      }
    };

    const svgElement = svgRef.current;
    if (svgElement) {
      svgElement.addEventListener("mousemove", handleMouseEnter);
      svgElement.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (svgElement) {
        svgElement.removeEventListener("mousemove", handleMouseEnter);
        svgElement.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  return (
    <div style={{ perspective: "1000px" }}>
      <Image
        ref={svgRef}
        className="transition-transform duration-[0.3s] ease-[ease-out]"
        style={{
          transformStyle: "preserve-3d",
          width: "80vw",
          maxWidth: "700px",
        }}
        alt={alt}
        src={src}
        width={width}
        height={height}
        priority
      />
    </div>
  );
};

export default TiltingImg;

import React from "react";
import TiltingImg from "./TiltingImg";
import CallToAction from "./UI/CallToAction";

interface MainHeaderProps {
  onListenNowClick?: () => void;
  className: string;
}

const MainHeader: React.FC<MainHeaderProps> = ({
  onListenNowClick,
  className,
}) => {
  return (
    <div
      className={`flex w-screen flex-col items-center justify-center gap-4 ${className}`}
    >
      <TiltingImg
        src="./logo_title.svg"
        alt="This one time at summer camp"
        height={75}
        width={300}
      />
      <button
        onClick={onListenNowClick}
        disabled={!onListenNowClick}
        className="btn-primary btn shadow-lg hover:shadow disabled:invisible"
      >
        Listen Now!
      </button>

      <CallToAction />
    </div>
  );
};

export default MainHeader;

import dynamic from "next/dynamic";
import useSpotify from "~/hooks/use_spotify.hook";
import EpisodeTile from "./EpisodeTile";
import Link from "next/link";
import type PodcastEpisodeLiteEntity from "~/models/podcast_episode_lite.entity";

const PlayerEmbed = dynamic(() => import("./PlayerEmbed"), {
  ssr: false,
});

interface PodcastProps {
  podcasts: PodcastEpisodeLiteEntity[];
  full?: boolean;
}

const Podcasts: React.FC<PodcastProps> = ({ podcasts, full }) => {
  const { selectedEpisode, setNewEpisode } = useSpotify();

  return (
    <div className="flex w-screen flex-col items-center justify-center gap-4 p-4 md:w-full">
      <h3 className="text-center text-2xl font-bold">Recent Episodes</h3>
      <div
        className={`${
          full ? "" : "h-[675px]"
        } w-full max-w-full overflow-auto md:max-w-[600px] lg:max-w-[850px]`}
      >
        <div className="flex flex-col gap-4">
          {podcasts.map((episode) => (
            <div key={episode.spotifyId}>
              {selectedEpisode &&
              episode.spotifyId === selectedEpisode.spotifyId ? (
                <>
                  <PlayerEmbed episodeId={episode.spotifyId} />
                  {episode.slug && (
                    <Link
                      href={`episodes/${episode.slug.current}`}
                      className="mr-2 self-end"
                      title={`More on ${episode.name}`}
                    >
                      See more on {episode.name}
                    </Link>
                  )}
                </>
              ) : (
                <EpisodeTile
                  episode={episode}
                  isSelected={Boolean(
                    selectedEpisode &&
                      episode.spotifyId === selectedEpisode.spotifyId
                  )}
                  onEpisodeSelected={setNewEpisode}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      {!full && (
        <Link
          href="episodes"
          className="btn-primary btn shadow-lg hover:shadow"
          title="See More Episodes"
        >
          More Episodes
        </Link>
      )}
    </div>
  );
};

export default Podcasts;

import React from "react";
import { api } from "~/utils/api";

const Contact = () => {
  const {
    mutate: sendEmailMutate,
    isLoading,
    isError,
    error,
    isSuccess,
    reset,
  } = api.contact.email.useMutation();

  const handleOnTextSubmit = ({
    email,
    senderName,
    message,
    consentToContact,
  }: ContactFormData) => {
    sendEmailMutate({
      email,
      senderName,
      message,
      consentToContact,
    });
  };

  return (
    <div className="m-4 max-w-prose rounded-xl bg-neutral p-8 text-center text-accent shadow-xl">
      <>
        {isSuccess ? (
          <div className="flex max-w-[500px] flex-col gap-8">
            <h3 className="w-full  text-center text-3xl font-medium">
              Thanks for sharing!
            </h3>
            <p>
              Tune into the next episode to see if your story has been shared
              live on air by our wonderful hosts
            </p>
            <button className="btn-primary btn" onClick={reset}>
              Got another story?
            </button>
          </div>
        ) : (
          <>
            <h3 className="text-bold mb-4 text-center text-3xl">
              Tell us your camp stories!
            </h3>
            <p>We&apos;d love to hear them!</p>

            <EmailContactForm
              {...{
                isLoading,
                isError,
                error,
                handleOnSubmit: handleOnTextSubmit,
              }}
            />
          </>
        )}
      </>
    </div>
  );
};

interface EmailContactProps {
  handleOnSubmit: (data: ContactFormData) => void;
  isLoading: boolean;
  isError: boolean;
  error: { message: string } | null;
}

type ContactFormData = {
  email: string;
  senderName: string;
  message: string;
  consentToContact: boolean;
};

const EmailContactForm: React.FC<EmailContactProps> = ({
  isLoading,
  isError,
  error,
  handleOnSubmit,
}) => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get("name") as string;
    const email = formData.get("email") as string;
    const message = formData.get("message") as string;
    const consent = (formData.get("consent-to-contact") as string) == "on";

    handleOnSubmit({
      email,
      senderName: name,
      message,
      consentToContact: consent,
    });
  };

  return (
    <>
      <p>If you&apos;re lucky then we might read your story live on air</p>
      <form
        className="mt-4 flex w-full flex-col items-center justify-center gap-4"
        onSubmit={onSubmit}
      >
        <div className="form-control w-full">
          <label className="label" htmlFor="name">
            <span>Name</span>
          </label>
          <input
            name="name"
            id="name"
            type="Text"
            placeholder="e.g. Treebeard"
            required
            className="input-bordered input-primary input w-full text-black"
          />
        </div>
        <div className="form-control w-full">
          <label className="label" htmlFor="email">
            <span>Email</span>
          </label>
          <input
            name="email"
            id="email"
            type="email"
            placeholder="e.g. treebeard@fangorn.com"
            required
            className="input-bordered input-primary input w-full text-black"
            autoComplete="email"
          />
        </div>
        <div className="form-control w-full">
          <label className="label" htmlFor="message">
            <span>Your story</span>
          </label>
          <textarea
            name="message"
            id="message"
            required
            className="textarea-bordered textarea-primary textarea max-h-44 w-full text-black"
            placeholder="e.g. I met a little pony in the woods..."
            autoCapitalize="sentences"
          />
        </div>
        <div className="flex flex-col items-start justify-start gap-2">
          <label className="label cursor-pointer text-start">
            <input
              name="consent-to-contact"
              id="consent-to-contact"
              type="checkbox"
              className="checkbox-primary checkbox mr-4 bg-base-100"
              autoComplete="off"
            />
            <span>
              Would you be <strong>ok</strong> if we contacted you about your
              story?
            </span>
          </label>
        </div>
        <button className="btn-primary btn w-full" type="submit">
          {isLoading ? "Sending..." : "Send"}
        </button>
      </form>

      {isError && (
        <div className="collapse text-error">
          <input type="checkbox" />
          <div className="collapse-title text-center text-xl font-medium">
            Something went wrong! Please try again.
          </div>
          <div className="collapse-content">
            <p>{error?.message}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default api.withTRPC(Contact);

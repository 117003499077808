import type { GetStaticProps, InferGetStaticPropsType } from "next";
import { Scroll, useUndampedProgress } from "jongleur";
import { type PropsWithChildren, useRef, useState } from "react";
import Footer from "~/components/Footer";
import PodHead from "~/components/Head";
import Navbar from "~/components/Navbar";
import Podcasts from "~/components/Podcast/Podcasts";
import Description from "~/components/Description";
import ScrollableModelViewer from "~/components/ScrollableModel/ScrollableModelViewer";
import Contact from "~/components/Contact";
import { clips } from "~/components/ScrollableModel/keyframes";
import { api } from "~/utils/api";
import HostDescription from "~/components/HostsDescription";
import { type DehydratedState } from "@tanstack/react-query";
import {
  dehydrateState,
  serversideHelpers,
} from "~/utils/serverside_helper.util";
import AppConstants from "~/constants/app.constants";
import MainHeader from "~/components/MainHeader";

export const getStaticProps: GetStaticProps<{
  trpcState: DehydratedState;
}> = async () => {
  await serversideHelpers.content.getEpisodes.prefetch({
    take: AppConstants.HOMEPAGE_EPISODE_TAKE,
  });
  await serversideHelpers.content.getDescription.prefetch();
  await serversideHelpers.content.getHosts.prefetch();

  return {
    props: {
      // TODO: This should be fixed by SuperJSON but not working as of yet.
      trpcState: dehydrateState(serversideHelpers.dehydrate),
    },
    revalidate: AppConstants.DEFAULT_REVALIDATE_IN_SECONDS,
  };
};

export default function HomePage({}: InferGetStaticPropsType<
  typeof getStaticProps
>) {
  const progress = useUndampedProgress(clips);
  const [page, setPage] = useState(0);
  const podcastsRef = useRef<HTMLDivElement>(null);

  const { data: podcasts } = api.content.getEpisodes.useQuery(
    {
      take: AppConstants.HOMEPAGE_EPISODE_TAKE,
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const { data: description } = api.content.getDescription.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: hosts } = api.content.getHosts.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleScroll = (page: number) => {
    setPage(page);
  };

  const onListenNowClick = () => {
    progress(4);
    podcastsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <PodHead />
      <main className="h-d-screen overflow-hidden bg-gradient-to-t from-orange-400 via-orange-300 to-indigo-500">
        <div className="absolute left-0 right-0 top-0 z-10 w-full">
          <Navbar showLogo={page !== 0} />
        </div>
        <ScrollableModelViewer onScroll={handleScroll}>
          <Scroll.At at={0} align={"center"} placement={["start", "center"]}>
            <MainHeader
              onListenNowClick={onListenNowClick}
              className="h-d-screen mt-48"
            />
          </Scroll.At>
          <Scroll.At at={2} align={"start"} placement={["center", "start"]}>
            <OuterWrapper>
              <div className="flex w-screen justify-center md:min-h-[400px] lg:w-[99vw] lg:justify-start">
                <div className="mx-0 flex flex-col items-center justify-center rounded-xl bg-base-100/50 p-0 shadow-xl backdrop-blur-sm md:p-8 lg:mx-12">
                  <Description
                    title={description?.title}
                    description={description?.content}
                  />
                </div>
              </div>
            </OuterWrapper>
          </Scroll.At>
          <Scroll.At at={4} align="center" placement={["center", "center"]}>
            <OuterWrapper>
              <div className="flex min-h-[400px] w-screen justify-center lg:w-[99vw] lg:justify-start">
                <div className="mx-0 flex flex-col items-center justify-center gap-8 rounded-xl bg-base-100/50 p-0 shadow-xl backdrop-blur-sm md:p-8 lg:mx-12">
                  {hosts && <HostDescription hosts={hosts} />}
                </div>
              </div>
            </OuterWrapper>
          </Scroll.At>
          <Scroll.At at={6} align={"end"} placement={["center", "end"]}>
            <OuterWrapper>
              <div className="flex min-h-[400px] w-screen justify-center lg:w-[99vw] lg:justify-end">
                <div
                  className="mx-0 flex flex-col items-center justify-center gap-8 rounded-xl bg-base-100/50 p-0 shadow-xl backdrop-blur-sm md:p-8 lg:mx-12"
                  ref={podcastsRef}
                >
                  {podcasts && <Podcasts podcasts={podcasts} />}
                </div>
              </div>
            </OuterWrapper>
          </Scroll.At>
          <Scroll.At at={8} align={"center"} placement={["center", "center"]}>
            <OuterWrapper>
              <div className="flex min-h-[600px] w-screen justify-center md:w-[90vw] lg:w-[50vw]">
                <div className="flex flex-row items-center justify-center rounded-xl bg-base-100/50 p-0 shadow-xl backdrop-blur-sm md:p-8">
                  <Contact />
                </div>
              </div>
            </OuterWrapper>
          </Scroll.At>
          <Scroll.At at={9} align="center" placement={["center", "center"]}>
            <div className="w-screen">
              <Footer full />
            </div>
          </Scroll.At>
        </ScrollableModelViewer>
      </main>
    </>
  );
}

const OuterWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex h-[200vh] flex-row items-center justify-center">
      {children}
    </div>
  );
};

import Image from "next/image";
import Link from "next/link";
import type { PropsWithChildren } from "react";
import type PodcastEpisodeLiteEntity from "~/models/podcast_episode_lite.entity";
import { stringDateToDisplayableDate } from "~/utils/helper.utils";

interface EpisodeTileProps {
  episode: PodcastEpisodeLiteEntity;
  isSelected: boolean;
  onEpisodeSelected: (episode: PodcastEpisodeLiteEntity) => void;
}

const EpisodeTile: React.FC<EpisodeTileProps> = ({
  episode,
  isSelected,
  onEpisodeSelected,
}) => {
  const moreUrl = episode.slug ? `episodes/${episode.slug.current}` : "";

  return (
    <div className="flex h-[152px] flex-col flex-nowrap justify-between gap-0 rounded-xl bg-neutral p-2 shadow-lg md:flex-row md:gap-4 md:p-4">
      <LinkWrapper
        href={moreUrl}
        className="hidden aspect-square h-full self-center rounded-lg md:block"
      >
        <Image
          src={episode.images[1].url}
          alt={episode.name}
          className="rounded-lg"
          width={episode.images[1].width}
          height={episode.images[1].height}
        />
      </LinkWrapper>
      <LinkWrapper
        href={moreUrl}
        className="aspect-square h-full self-center rounded-lg md:hidden"
      >
        <Image
          src={episode.images[2].url}
          alt={episode.name}
          className="rounded-lg"
          width={episode.images[2].width}
          height={episode.images[2].height}
        />
      </LinkWrapper>
      <div className="h-full w-full self-start justify-self-start truncate text-neutral-content">
        <LinkWrapper href={moreUrl}>
          <h3
            className="truncate text-center text-sm font-bold md:text-left md:text-lg"
            title={episode.name}
          >
            {episode.name}
          </h3>
        </LinkWrapper>
        <p className="text-center text-sm md:text-left">
          {stringDateToDisplayableDate(episode.releaseDate)}
        </p>
        <p
          className="line-clamp-2 hidden truncate text-ellipsis md:block"
          title={episode.shortDescription}
        >
          {episode.shortDescription}
        </p>
      </div>
      <div className="flex flex-row justify-center gap-2 md:min-w-[140px]">
        <button
          onClick={() => onEpisodeSelected(episode)}
          className="btn-primary btn h-6 min-h-0 grow self-center md:min-h-12 md:h-12"
          disabled={isSelected}
        >
          Play
        </button>
        {episode.slug?.current && (
          <Link
            href={`episodes/${episode.slug?.current}`}
            className="btn-primary btn h-6 min-h-0 self-center md:min-h-12 md:h-12"
          >
            More
          </Link>
        )}
      </div>
    </div>
  );
};

interface LinkWrapperProps {
  href?: string;
  className?: string;
}

const LinkWrapper: React.FC<PropsWithChildren<LinkWrapperProps>> = ({
  href,
  children,
  ...props
}) => {
  if (!href) {
    return <span {...props}>{children}</span>;
  }

  return (
    <Link href={href} {...props}>
      {children}
    </Link>
  );
};

export default EpisodeTile;

import { orchestrate, helpers } from "jongleur";
import { Vector3 } from "three";

export const clips = orchestrate(
  {
    start: { opacity: 1, config: { interpolation: "linear" } },
    model: {
      rotation: new Vector3(0, 0, 0),
      position: new Vector3(0, 0, 0),
      lookAt: new Vector3(0, 0, 0),
    },
  },

  {
    start: {
      1: { opacity: helpers.state(0, undefined) },
    },
    model: {
      1: {
        position: helpers.state(new Vector3(0.7, 0, -1)),
        rotation: helpers.state(new Vector3(0, -0.5, 0)),
      },
      2: {
        position: helpers.state(new Vector3(0.7, 0, -1)),
        rotation: helpers.state(new Vector3(0, -0.5, 0)),
      },
      3: {
        position: helpers.state(new Vector3(0.7, 0, -1)),
        rotation: helpers.state(new Vector3(0, 6, 0)),
      },
      4: {
        position: helpers.state(new Vector3(0.7, 0, -1)),
        rotation: helpers.state(new Vector3(0, 6, 0)),
      },
      5: {
        position: helpers.state(new Vector3(0.75, 0.3, 0.75)),
        rotation: helpers.state(new Vector3(0, 0.25, 0)),
      },
      6: {
        position: helpers.state(new Vector3(0.75, 0.3, 0.75)),
        rotation: helpers.state(new Vector3(0, 0.25, 0)),
      },
      7: {
        position: helpers.state(new Vector3(0, 0, 0)),
        rotation: helpers.state(new Vector3(0, 0, 0)),
      },
      8: {
        position: helpers.state(new Vector3(0, 0, 0)),
        rotation: helpers.state(new Vector3(0, 0, 0)),
      },
    },
  },
  {
    damping: true,
    interpolation: "ease-in-out",
    length: 8,
  }
);

import ApplePodcastsTile from "./UI/podcast_tiles/ApplePodcastsTile";
import GooglePodcastsTile from "./UI/podcast_tiles/GooglePodcastsTile";
import SpotifyPodcastsTile from "./UI/podcast_tiles/SpotifyPodcastsTile";

interface DescriptionProps {
  title?: string;
  description?: string;
}

const Description: React.FC<DescriptionProps> = ({ title, description }) => {
  return (
    <div className="m-4 max-w-prose rounded-xl bg-neutral p-8 text-center text-accent shadow-xl">
      <h1 className="text-bold mb-4 text-center text-3xl">{title}</h1>
      <p>{description}</p>
      <div className="mt-4 flex flex-row flex-wrap justify-center gap-2">
        <a
          className="transition delay-150 duration-300 ease-in-out hover:scale-110 motion-reduce:transition-none motion-reduce:hover:transform-none"
          target="_blank"
          rel="noreferrer"
          href="https://open.spotify.com/show/5sTfLG6GhzJ1SYDY3kWLMR"
          title="Listen on Spotify"
        >
          <SpotifyPodcastsTile />
        </a>
        <a
          className="transition delay-150 duration-300 ease-in-out hover:scale-110 motion-reduce:transition-none motion-reduce:hover:transform-none"
          target="_blank"
          rel="noreferrer"
          href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xOTgxNTE5LnJzcw=="
          title="Listen on Google Podcasts"
        >
          <GooglePodcastsTile />
        </a>
        <a
          className="transition delay-150 duration-300 ease-in-out hover:scale-110 motion-reduce:transition-none motion-reduce:hover:transform-none"
          target="_blank"
          rel="noreferrer"
          href="https://podcasts.apple.com/podcast/id1621708943"
          title="Listen on Apple Podcasts"
        >
          <ApplePodcastsTile />
        </a>
      </div>
    </div>
  );
};

export default Description;

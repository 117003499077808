import { useState } from "react";
import type PodcastEpisodeLiteEntity from "~/models/podcast_episode_lite.entity";

/**
 * Spotify related hooks.
 * @returns
 */
const useSpotify = () => {
  const [selectedEpisode, setSelectedEpisode] =
    useState<PodcastEpisodeLiteEntity>();

  const setNewEpisode = (episode: PodcastEpisodeLiteEntity) => {
    setSelectedEpisode(episode);
  };

  return { selectedEpisode, setNewEpisode };
};

export default useSpotify;

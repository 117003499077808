import { type HostType } from "~/types/sanity.type";
import Image from "next/image";

interface HostDescriptionProps {
  hosts: HostType[];
}

const HostDescription: React.FC<HostDescriptionProps> = ({ hosts }) => {
  return (
    <div className="m-4 max-w-prose rounded-xl bg-neutral p-8 text-center text-accent shadow-xl">
      <h2 className="text-bold mb-4 text-center text-3xl">Your hosts</h2>
      <div className="flex flex-row flex-wrap justify-center gap-8">
        {hosts.map((host) => (
          <article className="card max-w-[24rem] bg-base-100" key={host.name}>
            <figure>
              <Image
                src={host.image}
                alt={host.name}
                width={384}
                height={447}
                className="h-auto w-auto rounded-xl"
              />
            </figure>
            <div className="card-body max-w-prose bg-neutral px-0 text-start">
              <p className="card-title">{host.name}</p>
              <p>{host.description}</p>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
};

export default HostDescription;

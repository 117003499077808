import { Canvas } from "@react-three/fiber";
import { Scroll, useRegister } from "jongleur";
import dynamic from "next/dynamic";
import { clips } from "./keyframes";
import React, { Suspense } from "react";
import { useProgress } from "@react-three/drei";
import MainHeader from "../MainHeader";
import { Transition } from "@headlessui/react";

const ModelViewer = dynamic(() => import("./ModelViewer"), {
  ssr: false,
});

interface ScrollableModelViewerProps {
  children: React.ReactNode;
  onScroll: (page: number) => void;
}

const ScrollableModelViewer: React.FC<ScrollableModelViewerProps> = ({
  onScroll,
  children,
}) => {
  const register = useRegister(clips);

  clips.subscribe((state) => {
    onScroll(Math.floor(state.last));
  });

  const loaded = useProgress((state: { active: boolean }) => !state.active);
  const loadedAmount = useProgress((state) => state.progress);

  return (
    <div className={`h-screen w-screen bg-transparent`}>
      <Transition
        show={!loaded}
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <Loader loadedAmount={loadedAmount} />
      </Transition>
      <div
        className={`h-screen w-screen bg-transparent transition-all duration-1000 ${
          loaded ? "" : "blur-sm"
        } `}
      >
        <Canvas camera={{ position: [0, 0, 0] }} frameloop="demand" shadows>
          <Suspense>
            <Scroll.Controls clips={clips} damping={2}>
              <ModelViewer
                modelRef={register("model")}
                scale={0.015}
                position={[0, -0.2, -0.2]}
              />
              <Scroll.Html>{children}</Scroll.Html>
            </Scroll.Controls>
          </Suspense>
        </Canvas>
      </div>
    </div>
  );
};

const Loader = ({ loadedAmount }: { loadedAmount: number }) => {
  const daysUntil = 100 - Math.floor(loadedAmount);
  return (
    <div
      className="h-d-screen w-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: "url(./background.png)",
      }}
    >
      <div className="h-d-screen flex w-full flex-col items-center justify-center gap-4">
        <div className="mt-36 flex flex-col">
          <label htmlFor="camp-progress" className="text-center">
            {daysUntil} days until camp starts
          </label>

          <progress
            id="camp-progress"
            className="progress w-56"
            value={loadedAmount}
            max="100"
          ></progress>
        </div>
        <MainHeader className="h-d-screen mt-48" />
      </div>
    </div>
  );
};

export default ScrollableModelViewer;
